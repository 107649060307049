body {
  margin: 0;
  box-sizing: border-box;
}

#root {
  max-width: 600px;
  margin: auto;
}

.MuiMobileStepper-dot {
  width: 6px !important;
  height: 6px !important;
  margin: 0 1.7px !important;
}

.MuiCollapse-wrapperInner,
.MuiCollapse-wrapper,
.MuiCollapse-root {
  display: inline !important;
}

.comment-card .MuiListItemAvatar-root {
  min-width: 40px !important;
}

.comment-card .MuiListItemSecondaryAction-root {
  top: 20px !important;
}

.MuiAppBar-root {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4) !important;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4) !important;
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4) !important;
}
